export const titleCase = str => {
    let words = str.split(" ");
    return words
        .map(word => {
            let letters = word.split("");
            letters[0] = letters[0] ? letters[0].toUpperCase() : letters[0];
            return letters.join("");
        })
        .join(" ");
};
