import { v4 as uuid } from "uuid";
import { api } from "./../app";
export const sendEmail = params => {
	if (!params.idempotencyToken) {
		params.idempotencyToken = uuid();
	}
	// Emailer should get its own idempotency token

	return new Promise(async (resolve, reject) => {
		try {
			const response = await $.ajax({
				method: "POST",
				url: `${api}/request`,
				headers: {
					"Content-Type": "application/json"
				},
				xhrFields: {
					withCredentials: true
				},
				data: JSON.stringify({
					reqId: "64de6200-eb26-44a6-8aa2-79c0f1f3c5f4",
					idempotencyToken: uuid(),
					invocationType: "Event",
					payload: params
				})
			});
			resolve(response);
		} catch (err) {
			reject(err);
		}
	});
};
// end sendEmail function
