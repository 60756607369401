import { trans } from "./trans";
import { dimmer } from "./dimmer";
import { humanDate } from "./humanDate";
import { request } from "./request";
import { sendEmail } from "./sendEmail";
import { preview } from "./preview";
import { scroll } from "./scroll";
import { fetchUpdatedThumbs } from "./fetchUpdatedThumbs";
import { playButton } from "./playButton";
import { modal } from "./modal";
import { notify } from "./notify";
import { lang, metaFields } from "./../app";
import { v4 as uuid } from "uuid";
// library imports

if (lang != "en") {
	let _lang = lang === "cn" ? "zh" : lang;
	const customLang = require(`flatpickr/dist/l10n/${_lang}.js`).default[_lang];
	flatpickr.localize(customLang);
}
// localize calendar picker for non-English lang

let pageArr = location.pathname.split(`/${lang}/`);
let page = pageArr[pageArr.length - 1].replace(/\//g, "");

window.updateCart = () => {
	localStorage.setItem("newsroom_cart", JSON.stringify(window.cart));
	// this function always syncs localStorage

	let now = new Date().getTime();
	let updates = [];
	$("#cart_counter").remove();
	// remove any existing cart presentation

	const continueUpdate = () => {
		let cart_count = Object.keys(window.cart).length;
		let dropContent;
		if (cart_count) {
			dropContent = `<p>${trans("Download Overview")}</p><div id="cart_list_wrapper">${(function () {
				let rows = "";
				for (let key in window.cart) {
					let isVideo = window.cart[key].type.toLowerCase() === "video";
					rows += `<div class="cart_info_row uk-flex uk-flex-between uk-flex-middle">
                            <div class="asset_info_wrapper uk-flex uk-flex-middle">
                                <div class="cart_thumb uk-cover-container" ${isVideo ? ` style="position: relative"` : ""}>
                                    <img uk-cover src="${window.cart[key].thumb}"/>
                                    ${isVideo ? playButton(25) : ""}
                                </div>
                                <div class="cart_name">${window.cart[key].name}</div>
                            </div>
                            <span data-button data-id="${key}" class="cart_remove_button" uk-icon="icon: close; ratio: .8;"></span>
                        </div>`;
				}
				return rows;
			})()}</div><p><a id="view_cart_link" class="underline">${trans("View Requested Downloads")}</a></p>`;
		} else {
			dropContent =
				page != "media"
					? `<p>${trans("Visit the Multimedia Center to add assets to your collection")}</p>
            <p><a href="/${lang}/media/" class="underline">${trans("Multimedia Center")}</a></p>`
					: `<p>${trans("There are currently no assets in your cart.")}</p>`;
		}

		let cartCounter = $(`<div id="cart_counter" class="uk-navbar-nav uk-inline">
        <span class="header_toggle"><span uk-icon="icon: album; ratio: 1.2"></span> <span id="cart_count">(${cart_count})</span></span>
        <div id="cart_drop" uk-drop="mode: click; pos: bottom-left;">
            <div class="cart_counter_drop uk-card uk-card-body uk-card-hover uk-text-center">
                ${dropContent}
            </div>
        </div>
    </div>`);

		cartCounter
			.find("#view_cart_link")
			.off("click")
			.on("click", function () {
				reviewCart();
			});
		// click handler for viewing cart

		cartCounter.find(".cart_remove_button").on("click", function () {
			let id = $(this).attr("data-id");
			delete window.cart[id];
			localStorage.setItem("newsroom_cart", JSON.stringify(window.cart));
			// keep local storage synced

			cart_count = Object.keys(window.cart).length;
			$("#cart_count").text(`(${cart_count})`);
			// keep cart counter synced

			if (!cart_count) {
				$(".cart_counter_drop").html(
					page != "media"
						? `<p>${trans("Visit the Multimedia Center to add assets to your collection")}</p>
            <p><a href="/${lang}/media/" class="underline">${trans("Multimedia Center")}</a></p>`
						: `<p>${trans("There are currently no assets in your cart.")}</p>`
				);
			}

			$(this).parents(".cart_info_row").remove();
			if (page === "media") {
				$(`.add_link[data-id='${id}']`).removeAttr("incart").text(trans("Add To Collection"));
			}
		});
		// cart remove button click handler

		$(window.isMobile ? "#right_nav" : "#left_nav").append(cartCounter);
	};
	// end continueUpdate function

	for (let key in window.cart) {
		if (window.cart[key].thumb) {
			let expires = new URLSearchParams(window.cart[key].thumb.substring(window.cart[key].thumb.indexOf("?"))).get("Expires");
			if (expires && parseInt(expires) * 1000 <= now) {
				updates.push({ index: updates.length + 1, external: key, uuid: window.cart[key].uuid });
			}
		}
	}
	if (updates.length) {
		// some assets have expired thumbnails;
		// let's refresh those

		$("#cart_counter").css("position", "relative").append(dimmer(0.6));
		fetchUpdatedThumbs(updates)
			.then(res => {
				res.forEach(update => {
					window.cart[update.external].thumb = update.thumb;
				});
				localStorage.setItem("newsroom_cart", JSON.stringify(window.cart));
				// update local storage with updated cart info

				$("#cart_counter").find(".dimmer").remove();
				continueUpdate();
			})
			.catch(err => {
				$("#cart_counter").find(".dimmer").remove();
				console.error("Error updating cart assets:", err);
				continueUpdate();
			});
	} else {
		continueUpdate();
	}
};
// end updateCart function

const reviewCart = () => {
	// if (!$("#flatpickrCSS").length) {
	// 	$("body").append(`<link href="/dist/css/news.css" id="flatpickrCSS" rel="stylesheet"/>`);
	// }
	// need flatpickr CSS, which already exists in news.css

	const reviewModal = modal(
		`<div id="assetModal">
                    <div id="asset_modal_header" class="page-heading uk-heading-small uk-text-center">
                        ${trans("Asset Request")}
                    </div>
                    <div uk-grid class="uk-padding-large uk-padding-remove-vertical">
                        <div class="uk-width-1-1 uk-width-3-5@s">
                            <div class="order_header">${trans("Your Order")}</div>
                            <table id="order_detail_table">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th class="uk-invisible">${trans("File Size")}</th>
                                        <th><span class="uk-visible@s">${trans("Remove")}</span></th>
                                    </tr>
                                </thead>
                                <tbody></tbody>
                            </table>
                        </div>
                        <div class="uk-width-1-1 uk-width-2-5@s">
                            <div class="order_header" data-translate-key="Your Details">${trans("Your Details")}</div>
                            <form id="order_form" class="uk-form uk-position-relative" enctype="multipart/form-data" autocomplete="off">
                                <div class="field">
                                    <label for="company"><span data-translate-key="Company Name">${trans("Company Name")}</span><span class="asterisk">*</span></label>
                                    <input id="company" name="company" type="text" class="uk-input" />
                                </div>
                                <div class="field">
                                    <label for="email"><span data-translate-key="Email Address">${trans("Email Address")}</span><span class="asterisk">*</span></label>
                                    <input id="email" name="email" type="text" class="uk-input" />
                                </div>
                                <div class="uk-flex uk-flex-between@s uk-flex-wrap">
                                    <div class="field uk-width-1-1 uk-width-1-2@s name-input-wrapper">
                                        <label for="firstname"><span data-translate-key="First Name">${trans("First Name")}</span><span class="asterisk">*</span></label>
                                        <input id="firstname" name="firstname" type="text" class="uk-input" />
                                    </div>
                                    <div class="field uk-width-1-1 uk-width-1-2@s name-input-wrapper">
                                        <label for="lastname"><span data-translate-key="Last Name">${trans("Last Name")}</span><span class="asterisk">*</span></label>
                                        <input id="lastname" name="lastname" type="text" class="uk-input" />
                                    </div>
                                </div>
                                <div class="field">
                                    <label for="website"><span data-translate-key="Company Website">${trans("Company Website")}</span><br/><span class="helper_text" data-translate-key="Include protocol">${trans("Include protocol")}</span></label>
                                    <input id="website" name="website" type="text" class="uk-input" />
                                </div>
                                <div class="field">
                                    <label for="phone"><span data-translate-key="Phone number">${trans("Phone number")}</span><span class="asterisk">*</span></label>
                                    <input id="phone" name="phone" type="text" class="uk-input" />
                                </div>
                                <div class="field">
                                    <label for="city"><span data-translate-key="City">${trans("City")}</span><span class="asterisk">*</span></label>
                                    <input id="city" name="city" type="text" class="uk-input" />
                                </div>
                                <div class="field">
                                    <label for="country"><span data-translate-key="Country">${trans("Country")}</span><span class="asterisk">*</span></label>
                                    <input id="country" name="country" type="text" class="uk-input" />
                                </div>
                                <div class="field">
                                    <label for="duedate"><span data-translate-key="Due date for assets">${trans("Due date for assets")}</span><span class="asterisk">*</span></label>
                                    <input id="duedate" name="duedate" type="date" class="uk-input" />
                                </div>
                                <div class="field">
                                    <label for="description"><span data-translate-key="Provide a detailed description of how Gulfstream’s assets are to be used">${trans("Provide a detailed description of how Gulfstream’s assets are to be used")}</span><span class="asterisk">*</span></label>
                                    <textarea id="description" name="description" rows="5"></textarea>
                                </div>
                                <div id="required_notice" class="field"><span class="asterisk">*</span><span data-translate-key="Required Field">${trans("Required Field")}</span></div>
                                <div id="order_disclaimer" data-translate-key-html="order_disclaimer">${trans("order_disclaimer")}</div>
                                <button id="order_button" class="uk-button uk-button-primary uk-flex uk-flex-center uk-flex-middle" data-translate-key="Submit Request">${trans("Submit Request")}</button>
                            </form>
                        </div>
                    </div>
                </div>`,
		true,
		true,
		false,
		true,
		false
	);
	reviewModal.find("#modal_trademark_modal_link").on("click", function () {
		modal(`<div class="uk-padding">${trans("trademark_popup")}</div>`, true, false);
	});
	let cart = JSON.parse(localStorage.getItem("newsroom_cart"));
	let table = $("#order_detail_table");
	let cnt = Object.keys(cart).length;
	table
		.find("th")
		.eq(0)
		.text(`${cnt} ${cnt != 1 ? trans("Items") : trans("Item")}`);
	// update first column heading with item count

	// clear table body

	buildOrderTable();

	function buildOrderTable() {
		table.find("tbody").html("");
		for (let id in cart) {
			let isVideo = cart[id].type.toLowerCase() === "video";
			table.find("tbody").append(`<tr>
            <td>
                <div class="content_wrapper uk-flex uk-flex-middle">
                    <div class="order_thumb_wrapper uk-cover-container" ${isVideo ? ` style="position: relative"` : ""}>
                        <img src="${cart[id].thumb}" uk-cover />
                        ${isVideo ? playButton(40) : ""}
                    </div> 
                    <div class="order_name">${cart[id].name}</div>
                </div>
            </td>
            <td>
                <div class="content_wrapper uk-visible@s">${Math.round((cart[id].size / 1000) * 10) / 10} ${trans("megabytes")}</div>
            </td>
            <td>
                <div class="content_wrapper remove_button_wrapper uk-flex uk-flex-right uk-flex-middle">
                    <span data-id="${id}" class="remove_button" data-button uk-icon="icon: close"></span>
                </div>
            </td>
        </tr>`);
		}
		table.find(".remove_button").on("click", function () {
			let id = $(this).attr("data-id");
			delete cart[id];
			window.cart = cart;
			updateCart();
			buildOrderTable();
			if (page === "media") {
				$(`.add_link[data-id='${id}']`).removeAttr("incart").text(trans("Add To Collection"));
			}
			if (!Object.keys(window.cart).length) {
				UIkit.modal(reviewModal).hide();
				// no modal if cart is empty
			}
		});
		// click handler for asset remove button
	}
	// end buildOrderTable function

	flatpickr("#duedate", {
		altInput: true,
		altFormat: lang != "en" ? (lang === "cn" ? "Y\\年n\\月j\\日" : lang === "es" || lang === "pt" ? "j \\de F \\de Y" : lang === "ru" ? "j F Y \\г." : "F j, Y") : "F j, Y",
		dateFormat: "U",
		enableTime: true,
		defaultDate: new Date().setHours(23, 59, 59)
	});
	// initialize calendar input

	const form = $("#order_form");
	form.find("[name]").on("input", function () {
		$(this).parents(".field").removeClass("error");
	});
	// user input should result in error class removal

	$("#order_button")
		.off("click")
		.on("click", async function (e) {
			e.preventDefault();
			const validURL = new RegExp(
				"^(https?:\\/\\/)" + // protocol
					"((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
					"((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
					"(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
					"(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
					"(\\#[-a-z\\d_]*)?$",
				"i"
			);
			const validEmail = new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/, "i");
			form.find(".error").removeClass("error");
			let values = {};
			values.time = new Date().getTime();
			let valid = true;
			form.find("[name]").each(function () {
				let input = $(this).attr("name");
				let val = $(this).val();
				if (input === "duedate") {
					values.duedate = parseInt(val) * 1000;
					if (values.duedate < values.time) {
						$(this).parents(".field").addClass("error");
						valid = false;
					}
				} else {
					values[input] = val;
				}
				if (input === "website" && val) {
					if (!validURL.test(val)) {
						$(this).parents(".field").addClass("error");
						valid = false;
					}
					// website field is optional, but if they
					// enter something, it should be a valid URL
				}
				if (input === "email" && val) {
					if (!validEmail.test(val)) {
						$(this).parents(".field").addClass("error");
						valid = false;
					}
					// email value must be valid email format
				}
				if (input != "website" && !val) {
					$(this).parents(".field").addClass("error");
					valid = false;
				}
				// website field is optional
			});
			// some form validation
			if (!valid) {
				scroll(form.find(".field.error").eq(0), 0, 200, reviewModal);
			} else {
				values.assets = [];
				for (let key in window.cart) {
					values.assets.push(window.cart[key]);
				}
				const data = {
					id: uuid(),
					status: "new",
					firstname: values.firstname,
					lastname: values.lastname,
					email: values.email,
					company: values.company,
					phone: values.phone,
					city: values.city,
					country: values.country,
					website: values.website,
					duedate: values.duedate,
					use: values.description,
					assets: values.assets,
					time: values.time,
					lang: lang,
					type: "order",
					ttl: Math.round((values.time + 1000 * 60 * 60 * 24 * 365 * 5) / 1000)
					// orders expire 5 years after creation, per GD policy;
					// DynamoDB requires ttl values to be in seconds
				};
				let params = {
					reqId: "e7afce7f-0c86-4aa5-91b7-fd8fe5df2e90",
					data: data
				};
				try {
					await request(params, form);
					form.append(dimmer());
					try {
						await notifyAdmin(data);
					} catch (err) {
						throw `Error sending admin email: ${err}`;
					}
					try {
						await notifyUser(data);
						window.cart = {};
						updateCart();
						if (page === "media") {
							$(".add_link").removeAttr("incart").text(trans("Add To Collection"));
						}
						// empty cart and update media page
						// links

						form.find(".dimmer").remove();
						UIkit.modal(reviewModal).hide();
						preview("info", null, `${trans("Thank you")}`, `<div>${trans("Your request has been submitted. Check your inbox for a confirmation email with more information.")}</div>`);
					} catch (err) {
						throw `Error sending user notification email: ${err}`;
					}
				} catch (err) {
					// reviewModal.find(".dimmer").remove();
					notify("There was an error submitting your request", "danger");
					console.error("Error creating media request:", err);
				}
			}
		});
	// submit request button click handler

	const notifyAdmin = order => {
		return new Promise(async (resolve, reject) => {
			const time = humanDate(order.time, true, " at ");
			const refNo = uuid();
			let html = `<h3>Gulfstream News Media Request Notifier</h3>
            <p>A new media request has been submitted. Here are some details:</p>
            <p><strong>ID: ${order.id}</strong></p>
            <p><strong>Submitted by:</strong> ${order.firstname} ${order.lastname}<br/>
            <strong>Location:</strong> ${order.city}, ${order.country}<br/>
            <strong>Email:</strong> ${order.email}<br/>
            <strong>Phone:</strong> ${order.phone}<br/>
            <strong>Company:</strong> ${order.company} (${order.website ? `website: <a href="${order.website}" target="_blank">${order.website}</a>` : "No website provided"})<br/>
            <strong>Requested:</strong> ${time}<br/>
            <strong>Wanted by:</strong> ${humanDate(order.duedate, true, " at ")}<br/><br/>
            <strong>Usage notes:</strong> ${order.use}<br/><br/>
            <strong>Requested assets:</strong><br/><br/>
            ${(function () {
				return order.assets
					.map(
						(asset, index) => `<strong>${index + 1}.</strong> ${asset.name}<br/>
                        <strong>Type:</strong> ${asset.type}<br/>
                        <strong>Category/Subcategory:</strong> ${metaFields.find(item => Object.keys(item)[0] === asset.locator.field)[asset.locator.field]} > ${asset.locator.value}<br/><br/>`
					)
					.join("");
			})()}
            </p>
            <p>Log in to the <a href="${location.origin}/admin/?then=orders/?id=${order.id}">Gulfstream Newsroom Order Admin Page</a> to manage this request.</p>
			<p style="color: #cccccc;">Ref: ${refNo}</p>`;
			const params = {
				noArchive: true, // don't need to keep these in DS email recs
				idempotencyToken: refNo,
				emailTo: "media.requests@gulfstream.com",
				emailBcc: "digital.marketing@gulfstream.com",
				emailReplyTo: "digital.marketing@gulfstream.com",
				emailFrom: "Gulfstreamnews.com <notifier@gulfstream.aero>",
				emailSubject: `Gulfstreamnews.com media request from ${order.firstname} ${order.lastname}`,
				emailHTML: html,
				emailSource: "newsroom@gulfstream.aero",
				formId: "Newsroom media request: Admin notification",
				userId: order.email
			};
			try {
				const response = await sendEmail(params);
				resolve(response);
			} catch (err) {
				reject(err);
			}
		});
	};
	// end notifyAdmin function

	const notifyUser = order => {
		return new Promise(async (resolve, reject) => {
			const time = humanDate(order.time, true, ` ${trans("at")} `);
			const refNo = uuid();
			let html = `<h3>${trans("Your Gulfstream media assets request")}</h3>
            <p>${trans("This email confirms your recent request for Gulfstream media assets.")}</p>
            <strong>${trans("Order ID")}: ${order.id}</strong><br/>
            <p><strong>${trans("Your Details")}:</strong><br/>
            <strong>${trans("Name")}:</strong> ${order.firstname} ${order.lastname}<br/>
            <strong>${trans("City")}:</strong> ${order.city} ( ${order.country} )<br/>
            <strong>${trans("Email Address")}:</strong> ${order.email}<br/>
            <strong>${trans("Phone Number")}:</strong> ${order.phone}<br/>
            <strong>${trans("Company name")}:</strong> ${order.company} (${trans("website")}: ${order.website ? `<a href="${order.website}" target="_blank">${order.website}</a>` : trans("None provided")})<br/>
            <strong>${trans("Requested")}:</strong> ${time}<br/>
            <strong>${trans("Wanted by")}:</strong> ${humanDate(order.duedate, true, ` ${trans("at")} `)}<br/><br/>
            <strong>${trans("Usage")}:</strong> ${order.use}<br/><br/>
            <strong>${trans("Request details")}:</strong><br/><br/>
                ${(function () {
					return order.assets
						.map(
							(asset, index) => `<strong>${index + 1}.</strong> ${asset.name}<br/>
                    <strong>${trans("Asset Type")}:</strong> ${trans(asset.type)}<br/>
                    <strong>${trans("Category")}/${trans("Subcategory")}:</strong> ${trans(metaFields.find(item => Object.keys(item)[0] === asset.locator.field)[asset.locator.field])} > ${trans(asset.locator.value)}<br/><br/>`
						)
						.join("");
				})()}
            </p>
            <p>${trans("You will be notified when your request has been processed. You can visit this link to check on it in the meantime")}: <a href="${location.origin}/${lang}/orders/?id=${order.id}">${trans("Gulfstream Newsroom Orders")}</a></p>
			<p style="color: #cccccc;">Ref: ${refNo}</p>`;
			const params = {
				noArchive: true, // don't need to keep these in DS email recs
				idempotencyToken: refNo,
				emailTo: order.email,
				emailReplyTo: "media.requests@gulfstream.com",
				emailFrom: "Gulfstreamnews.com <notifier@gulfstream.aero>",
				emailReplyTo: "media.requests@gulfstream.com",
				emailSubject: trans("Your Gulfstream media assets request"),
				emailHTML: html,
				emailSource: "newsroom@gulfstream.aero",
				formId: "Newsroom media request: User notification",
				userId: order.email
			};
			try {
				const response = await sendEmail(params);
				if (response.success) {
					resolve(response.data);
				} else {
					reject(response.data);
				}
			} catch (err) {
				reject(err);
			}
		});
	};
	// end notifyUser function
};
// end reviewCart function
