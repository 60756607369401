export const scroll = (selector, addOffset = 0, time = 200, context = $("html, body")) => {
	let offset = selector.length ? selector.offset().top : 100;
	context.animate(
		{
			scrollTop: offset - addOffset
		},
		time
	);
	return true;
};
