import { api } from "./../app";

export const fetchUpdatedThumbs = (updates, index = 0, imageContainer) => {
	return new Promise(async (resolve, reject) => {
		try {
			const response = await $.ajax({
				method: "POST",
				url: `${api}/request`,
				headers: {
					"Content-Type": "application/json"
				},
				xhrFields: {
					withCredentials: true
				},
				data: JSON.stringify({
					reqId: "ff86dbba-e90e-4b27-8ff7-82f2822256e6",
					id: updates[index].uuid,
					expand: "thumbnails"
				})
			});
			if (response.success) {
				if (imageContainer) {
					imageContainer.find(`img[data-id='${response.data.id}']`).attr("src", response.data.thumbnails["300px"].url);
				}
				updates[index].thumb = response.data.thumbnails["300px"].url;
				if (index === updates.length - 1) {
					resolve(updates);
				} else {
					index++;
					resolve(fetchUpdatedThumbs(updates, index, imageContainer));
				}
			} else {
				throw response.data;
			}
		} catch (err) {
			reject(err);
		}
	});
};
// end fetchUpdatedThumbs function
