import { trans } from "./trans";
import { metaFields } from "./../app";
import { titleCase } from "./titleCase";

export const preview = (type, srcData, title = `Attention <span uk-icon="icon: warning"></span>`, content, collection, index) => {
	let previewModal = $("#previewModal");
	previewModal.find(".preview-meta").remove();
	// remove old meta presentation

	if (type != "info" && srcData.locator) {
		previewModal.find(".uk-modal-header").prepend(`<p class="preview-meta">${titleCase(trans(metaFields.find(item => Object.keys(item)[0] === srcData.locator.field)[srcData.locator.field]))} <span uk-icon="icon: chevron-right; ratio: ${window.isMobile ? 0.6 : 0.8}"></span> ${titleCase(srcData.locator.value)}</p>`);
	}
	// leave breadcrumbs of locator data

	previewModal.find("#modalTitle").html(type != "info" ? `<div class="title_text"">${srcData.name}</div> ${index != "undefined" ? (window.isMobile ? `<div><span id="preview-gallery-prev" uk-icon="icon: chevron-left;"></span><span id="preview-gallery-next" uk-icon="icon: chevron-right;"></span></div>` : `<span class="uk-align-left uk-align-right@s uk-margin-remove"><span id="preview-gallery-prev" uk-icon="icon: chevron-left;"></span><span id="preview-gallery-next" uk-icon="icon: chevron-right;"></span></span>`) : ""}` : title);
	switch (type.toLowerCase()) {
		case "document":
		case "pdf":
			previewModal.find("#modalBody").removeClass("uk-flex");
			content = $(
				srcData.document && srcData.document.viewer && srcData.document.viewer.html
					? `<div class="documentPreview">${srcData.document.viewer.html}</div>`
					: `<div class="documentPreview">
                <iframe src="${srcData.url}" width="100%" height="100%" allowfullscreen webkitallowfullscreen></iframe>
            </div>`
			);
			break;
		case "video":
			previewModal.find("#modalBody").removeClass("uk-flex");
			content = $(`<div class="video_preview_wrapper">${srcData.video && srcData.video.player && srcData.video.player.html ? srcData.video.player.html : "Preview unavailable"}</div>`);
			break;
		case "image":
			previewModal.find("#modalBody").addClass("uk-flex");
			content = $(`<img src="${srcData.preview ? srcData.preview : srcData.thumb ? srcData.thumb : srcData.url}">`);
			break;
		case "info":
		default:
			previewModal.find("#modalBody").removeClass("uk-flex");
	}
	previewModal.find("#modalBody").html(content);
	previewModal.find("[data-modal-action] [data-button]").on("click", function () {
		UIkit.modal(previewModal).hide();
		// modal action buttons should dismiss modal
	});
	if (index != "undefined") {
		// set click handlers for prev - next buttons
		$("#preview-gallery-prev")
			.off("click")
			.on("click", function () {
				collection.eq(index === 0 ? collection.length - 1 : index - 1).trigger("click");
			});
		$("#preview-gallery-next")
			.off("click")
			.on("click", function () {
				collection.eq(index === collection.length - 1 ? 0 : index + 1).trigger("click");
			});
	}
	UIkit.modal(previewModal).show();
};
// end preview function
